import { Grid, Stack, Typography } from '@mui/material';
import { RelativeInfo } from 'api/consentManagement/consent-management-service';
import { SectionBox } from 'components/common';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { formatPersonalNumberInput } from 'features/common/utils';
import {
  SignerData,
  SignerDataErrors,
  SignerRelativeDeceased,
} from 'iPedigree/features/common/SignerRelativeDeceased';
import { useTranslation } from 'react-i18next';

export type RelativeData = RelativeInfo & {
  specificProxySigner?: boolean;
};

export type RelativeDataErrors = {
  [key in keyof RelativeData]?: string | undefined;
};

interface RelativeDeceasedFormData {
  relativeData: RelativeData;
  setRelativeData: (data: RelativeData) => void;
  errors: SignerDataErrors;
  setErrors: (errors: SignerDataErrors) => void;
}

export const RelativeDeceasedController = (props: RelativeDeceasedFormData) => {
  const { t } = useTranslation(['consent', 'common']);
  const { translateRelationship } = useRelationshipOptions();

  const { relativeData, errors, setRelativeData, setErrors } = props;

  const handleSignerTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value as 'first-degree' | 'specific-relative';
    setRelativeData({
      ...relativeData,
      specificProxySigner: newValue === 'specific-relative',
    });
  };

  const updateValue = (key: keyof SignerData, value: string | null) => {
    setRelativeData({ ...relativeData, [key]: value });

    if (errors && errors[key]) {
      errors[key] = '';
      setErrors({ ...errors });
    }
  };

  const handleTextInputChangeFactory =
    (key: keyof SignerData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (['proxySignerPersonalNumber'].includes(key)) {
        value = formatPersonalNumberInput(value);
      }
      updateValue(key, value);
    };

  const handleAutocompleteInputChangeFactory =
    (key: keyof SignerData) => (value: string | null) => {
      updateValue(key, value);
    };

  const handleDeleteData = () => {
    setRelativeData({
      ...relativeData,
      proxySignerName: '',
      proxySignerToConsenteeRelationship: '',
      proxySignerPersonalNumber: '',
    });
  };

  return (
    <SectionBox>
      <Stack gap={4}>
        <Typography variant="h2">
          {t('edit-relative-deceased.section2.title')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack>
              <Typography variant="body1">{t('common:full-name')}</Typography>
              <Typography variant="h4">{relativeData.name}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack>
              <Typography variant="body1">
                {t('edit-relative-deceased.section2.relative.relation')}
              </Typography>
              <Typography variant="h4">
                {translateRelationship(relativeData.relationshipToPatient)}
              </Typography>
            </Stack>
          </Grid>
          {relativeData.personalNumber && (
            <Grid item xs={12} md={4}>
              <Stack>
                <Typography variant="body1">
                  {t(
                    'edit-relative-deceased.section2.relative.personal-number',
                  )}
                </Typography>
                <Typography variant="h4">
                  {relativeData.personalNumber}
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            <SignerRelativeDeceased
              specificProxySigner={relativeData.specificProxySigner}
              signerData={{
                proxySignerName: relativeData.proxySignerName,
                proxySignerToConsenteeRelationship:
                  relativeData.proxySignerToConsenteeRelationship,
                proxySignerPersonalNumber:
                  relativeData.proxySignerPersonalNumber,
              }}
              errors={errors}
              handleSignerTypeChange={handleSignerTypeChange}
              handleTextInputChangeFactory={handleTextInputChangeFactory}
              handleAutocompleteInputChangeFactory={
                handleAutocompleteInputChangeFactory
              }
              onDeleteClick={handleDeleteData}
            />
          </Grid>
        </Grid>
      </Stack>
    </SectionBox>
  );
};
