import { Stack } from '@mui/material';
import { ConsentManagementServiceProvider } from 'api/consentManagement/ConsentManagementServiceProvider';
import { StaffPatientServiceProvider } from 'api/patient/staff-patient-service';
import PdfServiceProvider from 'api/pdf/PdfServiceProvider';
import { StaffPedigreeCaseServiceProvider } from 'api/pedigreeCase/staff-pedigree-case-service';
import { UserServiceProvider } from 'api/user/user-service';
import i18n from 'assets/localization/i18n/iPedigreeI18nConfig';
import { NotificationProvider } from 'common/layouts/notification/NotificationProvider';
import { SnackbarNotification } from 'common/layouts/notification/SnackbarNotification';
import TopNav from 'common/layouts/TopNav';
import { withStaffAuthProvider } from 'features/auth/staff/StaffAuthProvider';
import { IPedigreeManagementServiceProvider } from 'iPedigree/api/iPedigreeManagement/IPedigreeManagementServiceProvider';
import { useStaffAuth } from 'iPedigree/common';
import DashboardPage from 'iPedigree/pages/DashboardPage';
import NewPatientPage from 'iPedigree/pages/NewPatientPage';
import SettingsPage from 'iPedigree/pages/SettingsPage';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import PatientPageRouter from './PatientPageRouter';

export const ROUTES = {
  newPatient: '/new-patient',
  patient: '/patient',
  settings: '/settings',
};

const Layout = () => {
  return (
    <Stack direction="column" sx={{ minHeight: '100vh' }}>
      <NotificationProvider>
        <TopNav containerMaxWidth="xl" settingsPath={ROUTES.settings} />
        <SnackbarNotification />
        <Outlet />
      </NotificationProvider>
    </Stack>
  );
};

const IPedigreeRouter = () => {
  const { accessToken, logout } = useStaffAuth();

  return (
    <I18nextProvider i18n={i18n}>
      <StaffPedigreeCaseServiceProvider
        accessToken={accessToken}
        logout={logout}
      >
        <IPedigreeManagementServiceProvider
          accessToken={accessToken}
          logout={logout}
        >
          <UserServiceProvider accessToken={accessToken} logout={logout}>
            <StaffPatientServiceProvider
              accessToken={accessToken}
              logout={logout}
            >
              <ConsentManagementServiceProvider
                accessToken={accessToken}
                logout={logout}
              >
                <PdfServiceProvider accessToken={accessToken}>
                  <Stack direction="column" sx={{ minHeight: '100vh' }}>
                    <BrowserRouter>
                      <Routes>
                        <Route element={<Layout />}>
                          <Route index element={<DashboardPage />} />
                          <Route
                            path={ROUTES.newPatient}
                            element={<NewPatientPage />}
                          />
                          <Route
                            path={ROUTES.patient + '/:patientId/*'}
                            element={<PatientPageRouter />}
                          />
                          <Route
                            path={ROUTES.settings}
                            element={<SettingsPage />}
                          />
                        </Route>
                      </Routes>
                    </BrowserRouter>
                  </Stack>
                </PdfServiceProvider>
              </ConsentManagementServiceProvider>
            </StaffPatientServiceProvider>
          </UserServiceProvider>
        </IPedigreeManagementServiceProvider>
      </StaffPedigreeCaseServiceProvider>
    </I18nextProvider>
  );
};

export default withStaffAuthProvider(IPedigreeRouter);
