import { LoadingButton } from '@mui/lab';
import { Button, Container, Stack, Typography } from '@mui/material';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import SectionBox from 'components/common/SectionBox';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Consent = {
  name: string;
  relationshipToPatient: string | null;
};

interface ConsentRequestReviewProps {
  patientName: string;
  patientPersonalNumber: string;
  relatives: Consent[];
  reminder?: boolean;
  onBackButtonClick: () => void;
  onContinueClick?: () => void;
  onContinueClickLoading?: boolean;
}

export const ConsentRequestReview = (props: ConsentRequestReviewProps) => {
  const { t } = useTranslation(['consent', 'common']);
  const { translateRelationship } = useRelationshipOptions();

  const {
    patientName,
    patientPersonalNumber,
    relatives,
    reminder,
    onBackButtonClick,
    onContinueClick,
    onContinueClickLoading,
  } = props;

  const title = reminder
    ? t('review-request-consent.reminder-title')
    : t('review-request-consent.title');

  const description = reminder
    ? t('review-request-consent.notification.reminder-title')
    : t('review-request-consent.notification.title');

  const personalNumberValue = useMemo(() => {
    return patientPersonalNumber.replace(/(\d{8})(\d+)/, '$1-$2');
  }, [patientPersonalNumber]);

  return (
    <Container>
      <Stack spacing={3}>
        <HeaderWithBackButton
          onBackButtonClick={onBackButtonClick}
          headerVariant="h1"
        >
          {title}
        </HeaderWithBackButton>
        <SectionBox>
          <Stack gap={0.5}>
            <Typography variant="body1">{description}:</Typography>
            <Typography variant="body1">
              {t('review-request-consent.notification.patient')}: {patientName}
            </Typography>
            <Typography variant="body1">
              {t('common:personal-number')}: {personalNumberValue}
            </Typography>
          </Stack>
          <Stack mt={4}>
            <Typography variant="body1">
              {t('review-request-consent.notification.for-relatives')}:
            </Typography>
            {relatives.map((relative) => (
              <Typography key={relative.name} variant="body1">
                {`\u2022 ${relative.name} (${translateRelationship(
                  relative.relationshipToPatient || '',
                )})`}
              </Typography>
            ))}
          </Stack>
        </SectionBox>
        <Stack
          direction="row"
          gap={3}
          width="100%"
          maxWidth={700}
          alignSelf="center"
        >
          <Button variant="outlined" onClick={onBackButtonClick} fullWidth>
            {t('common:button.back')}
          </Button>
          <LoadingButton
            fullWidth
            variant="contained"
            onClick={onContinueClick}
            loading={onContinueClickLoading}
          >
            {t('review-request-consent.send-message')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Container>
  );
};
