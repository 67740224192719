import { User } from 'api/user/user-service';
import { createContext, useContext } from 'react';
import { DrawerState, PedigreeRendererType } from '..';
import { HandleNodeAction } from './hooks/useHandleNodeAction';
import { Pedigree, PedigreeNode, PedigreeService } from './types';
import { DialogContentType } from './views/Dialog';
import { SnackbarMessageType } from './views/SnackbarMessage';

type PedigreeContextType = {
  drawerState: DrawerState | null;
  setDrawerState: (info: DrawerState | null) => void;
  drawerDefaultWidth: number;
  editMode: boolean;
  nodeSize: { width: number; height: number };
  NodeMenu: JSX.Element;
  pedigreeType: PedigreeRendererType;
  pedigree: Pedigree;
  setPedigree: (pedigree: Pedigree) => void;
  selectedNodeId: string | null;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  selectedNode: PedigreeNode | null;
  getLocalizedLabelOf: (nodeId: string) => string;
  highlightedNodeIds: string[] | null;
  setHighlightedNodeIds: (pedigreeNodeId: string[] | null) => void;
  stageRef: any;
  service: PedigreeService;
  user?: User;
  openNodeMenu: (
    anchorElement: HTMLElement,
    pedigreeNodeId: string | null,
  ) => void;
  closeNodeMenu: () => void;
  handleNodeAction: ({ action, pedigreeNodeId }: HandleNodeAction) => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleZoomReset: () => void;
  dialogContent: DialogContentType;
  setDialogContent: (content: DialogContentType) => void;
  snackbarMessage: SnackbarMessageType | null;
  setSnackbarMessage: (content: SnackbarMessageType | null) => void;
};

export const PedigreeContext = createContext<PedigreeContextType | null>(null);

export function usePedigreeContext() {
  const context = useContext(PedigreeContext);
  if (!context) {
    throw new Error(
      'usePedigreeContext must be used within a PedigreeContext.Provider',
    );
  }
  return context;
}
