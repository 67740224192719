import { Relative } from 'iPedigree/features/patientPage/consentReminder/RelativeSelector';

export const mockRelatives: Relative[] = [
  {
    id: '1',
    name: 'Lena Andersson',
    relationship: 'sister',
    deceased: false,
    status: 'pending',
    date: '2024-01-01',
  },
  {
    id: '2',
    name: 'Erik Johansson',
    relationship: 'maternal-uncle',
    deceased: true,
    status: 'pending',
    date: '2024-01-15',
  },
  {
    id: '3',
    name: 'Anna Lindberg',
    relationship: 'cousin',
    deceased: false,
    status: 'pending',
    date: '2024-02-01',
  },
];
