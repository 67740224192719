import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useUserService } from 'api/user/user-service';
import { formatPersonalNumber } from 'common/utils';
import { LoadingBackdrop } from 'components/common';
import SectionBox from 'components/common/SectionBox';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface ConsentSentConfirmationProps {
  patientName: string;
  relatives: {
    name: string;
    relationshipToPatient: string | null;
    personalNumber?: string;
  }[];
  localizationNamespace?: 'new-edit' | 'reminder-form';
}

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

function ConsentSentConfirmation(props: ConsentSentConfirmationProps) {
  const { t } = useTranslation(['consent', 'common']);
  const { translateRelationship } = useRelationshipOptions();
  const navigate = useNavigate();
  const userService = useUserService();
  const localizationNamespace = props.localizationNamespace || 'new-edit';

  const { data: userPreferences, isLoading } = useQuery(
    'userPreferences',
    userService.getCurrentUserPreferences,
  );

  if (isLoading) return <LoadingBackdrop />;

  return (
    <Container>
      <Stack spacing={3}>
        <Typography variant="h1">
          {t(`consent.${localizationNamespace}.sent-title`)}
        </Typography>
        <Typography variant="body1">
          {t(`consent.${localizationNamespace}.sent-text`, {
            patientName: props.patientName,
          })}
        </Typography>
        <SectionBox>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  {t('consent.table-labels.relative')}
                </CustomTableCell>
                <CustomTableCell>
                  {t('consent.table-labels.relationship')}
                </CustomTableCell>
                <CustomTableCell>{t('common:personal-number')}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.relatives.map((relative, idx) => (
                <TableRow key={`relative-${idx}`}>
                  <CustomTableCell>{relative.name}</CustomTableCell>
                  <CustomTableCell>
                    {translateRelationship(
                      relative.relationshipToPatient || '',
                    )}
                  </CustomTableCell>
                  <CustomTableCell>
                    {relative.personalNumber
                      ? formatPersonalNumber(relative.personalNumber)
                      : t('common:unknown')}
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SectionBox>
        {userPreferences === null && (
          <Alert
            severity="warning"
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
          >
            <Typography variant="body1">
              {t('consent.new-edit.sent-warning.title')}
            </Typography>
            <Typography variant="body1" mt={0.5}>
              <Link variant="body1" href={'/settings'}>
                {t('consent.new-edit.sent-warning.link')}
              </Link>{' '}
              {t('consent.new-edit.sent-warning.description')}
            </Typography>
          </Alert>
        )}
        <Stack
          direction="row"
          gap={3}
          width="100%"
          maxWidth={700}
          alignSelf="center"
        >
          <Button variant="outlined" fullWidth onClick={() => navigate('/')}>
            {t('consent.actions.to-home')}
          </Button>
          <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>
            {t('consent.actions.to-consents')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default ConsentSentConfirmation;
