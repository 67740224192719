import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { HeaderWithBackButton } from 'components/common';
import {
  PatientData,
  PatientDataErrors,
  PatientFormController,
} from 'iPedigree/features/common/PatientFormController';
import {
  validatePatientData,
  validateSingleRelativeData,
} from 'iPedigree/features/common/utils/form-validation';
import { RelativeDeceasedFormData } from 'iPedigree/pages/EditRelativeDeceasedPage';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  RelativeData,
  RelativeDataErrors,
  RelativeDeceasedController,
} from './RelativeDeceasedController';

interface EditRelativeDeceasedProps {
  initialFormValues: RelativeDeceasedFormData | null;
  onContinueClick: (data: RelativeDeceasedFormData) => void;
}

export const EditRelativeDeceasedController = (
  props: EditRelativeDeceasedProps,
) => {
  const { t } = useTranslation(['consent', 'common']);
  const theme = useTheme();
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const { initialFormValues, onContinueClick } = props;

  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [consentFor, setConsentFor] = useState<string>('');

  const [patientData, setPatientData] = useState<PatientData>({
    personalNumber: '',
    name: '',
    phoneNumber: '',
    email: '',
  });

  const [patientDataErrorMessages, setPatientDataErrorMessages] =
    useState<PatientDataErrors>({
      personalNumber: '',
      name: '',
      phoneNumber: '',
    });

  const [relativeData, setRelativeData] = useState<RelativeData>({
    name: '',
    relationshipToPatient: '',
    proxySignerName: '',
    proxySignerToConsenteeRelationship: '',
    proxySignerPersonalNumber: '',
    specificProxySigner: true,
  });

  const [relativeDataErrorMessages, setRelativeDataErrorMessages] =
    useState<RelativeDataErrors>({
      name: '',
      relationshipToPatient: '',
      proxySignerName: '',
      proxySignerToConsenteeRelationship: '',
      proxySignerPersonalNumber: '',
      specificProxySigner: '',
    });

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleRelativeChanged = (data: RelativeData) => {
    setIsDirty(true);
    setRelativeData(data);
  };

  const handlePatientChanged = (data: PatientData) => {
    setIsDirty(true);
    setPatientData(data);
  };

  const handleOnContinueClick = () => {
    const { valid: patientDataValid, errorMessages: patientDataErrorMessages } =
      validatePatientData(patientData);

    const { valid: relativesValid, errorMessages: relativeErrorMessages } =
      validateSingleRelativeData(relativeData);

    const hasPhoneOrEmail = patientData.phoneNumber || patientData.email;

    if (patientDataValid && relativesValid && hasPhoneOrEmail) {
      return onContinueClick({ patientData, relativeData, consentFor });
    }

    if (patientDataErrorMessages !== undefined || !hasPhoneOrEmail) {
      const errorMessages = {
        ...patientDataErrorMessages,
        phoneNumber: !hasPhoneOrEmail
          ? t('input-validation.phone-or-email.required')
          : patientDataErrorMessages?.phoneNumber,
      };

      setPatientDataErrorMessages(errorMessages);
    }

    if (relativeErrorMessages !== undefined)
      setRelativeDataErrorMessages(relativeErrorMessages);
    return;
  };

  const handleCancelClick = () => {
    return navigateBack();
  };

  useEffect(() => {
    const { patientData, relativeData, consentFor } = initialFormValues || {};
    if (patientData) setPatientData(patientData);
    if (relativeData) setRelativeData(relativeData);
    if (consentFor) setConsentFor(consentFor);
  }, [initialFormValues]);

  return (
    <Stack gap={3}>
      <HeaderWithBackButton
        headerVariant="h1"
        onBackButtonClick={handleCancelClick}
      >
        {t('edit-relative-deceased.title')}
      </HeaderWithBackButton>
      <PatientFormController
        patientData={patientData}
        setPatientData={handlePatientChanged}
        errors={patientDataErrorMessages}
        setErrors={setPatientDataErrorMessages}
        consentFor={consentFor}
      />
      <RelativeDeceasedController
        relativeData={relativeData}
        setRelativeData={handleRelativeChanged}
        errors={relativeDataErrorMessages}
        setErrors={setRelativeDataErrorMessages}
      />
      <Stack
        direction={isNotMobile ? 'row' : 'column'}
        justifyContent="center"
        spacing={4}
      >
        <Button
          variant="outlined"
          onClick={handleCancelClick}
          sx={{ minWidth: 334 }}
        >
          {t('common:button.cancel')}
        </Button>
        <Button
          disabled={!isDirty}
          variant="contained"
          onClick={handleOnContinueClick}
          sx={{ minWidth: 334 }}
        >
          {t('common:button.continue')}
        </Button>
      </Stack>
    </Stack>
  );
};
