import { ConsentManagementServiceProvider } from 'api/consentManagement/ConsentManagementServiceProvider';
import iPedigreeI18nInstance from 'assets/localization/i18n/iPedigreeI18nConfig';
import NotificationIsSentConfirmationView from 'common/features/pedigreeCase/sendPedigreeRequestReminder/NotificationIsSentConfirmationView';
import ConsentsTable from 'iPedigree/features/dashboard/table/ConsentsTable';
import PatientConsents from 'iPedigree/features/patientPage/patientConsents';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from '.';
import { DynamicFormPlayground } from './DynamicForm';
import { mockConsents } from './mocks/dashboard-consent-table.mock';
import { mockConsentRequest } from './mocks/patient-consent-table.mock';
import PedigreePlayground from './Pedigree';
import { RelativeSelectorWrapper } from './RelativeSelectorWrapper';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '/pedigree', element: <PedigreePlayground /> },
      { path: '/dynamic-form', element: <DynamicFormPlayground /> },
      {
        path: '/notification-is-sent-confirmation',
        element: (
          <NotificationIsSentConfirmationView
            title="Notification Is Sent Confirmation"
            description="This is a notification is sent confirmation view"
            actions={[
              { label: 'Back', onClick: () => {} },
              { label: 'Settings', onClick: () => {} },
            ]}
            promptNotification={true}
          />
        ),
      },
      {
        path: '/patient-consents',
        element: (
          <PatientConsents
            consentRequest={mockConsentRequest}
            onSendReminderButtonClick={() => {}}
          />
        ),
      },
      {
        path: '/dashboard-consent-table',
        element: <ConsentsTable consents={mockConsents} />,
      },
      {
        path: '/consent-reminder',
        element: <RelativeSelectorWrapper />,
      },
    ],
  },
]);

const PlaygroundRouter = () => {
  return (
    <I18nextProvider i18n={iPedigreeI18nInstance}>
      <ConsentManagementServiceProvider accessToken="test">
        <RouterProvider router={router} />
      </ConsentManagementServiceProvider>
    </I18nextProvider>
  );
};

export default PlaygroundRouter;
