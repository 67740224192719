import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import PageNotFoundView from 'features/common/views/PageNotFoundView';
import { getNewConsentNotification } from 'features/consentManagement/common/utils/notification-template';
import ConsentSentConfirmationView from 'features/consentManagement/common/views/ConsentSentConfirmationView';
import MessageVerificationView from 'features/consentManagement/common/views/MessageVerificationView';
import NoPendingConsentsView from 'features/consentManagement/common/views/NoPendingConsentsView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const SendReminder: React.FC = () => {
  const { t } = useTranslation('all');
  const navigate = useNavigate();
  const { consentRequestId } = useParams();
  const service = useConsentManagementService();
  const { translateRelationship } = useRelationshipOptions();

  const [state, setState] = React.useState<
    'notification-view' | 'confirmation' | 'not-found' | 'no-pending-consents'
  >('notification-view');
  const [notification, setNotification] = React.useState<string>('');
  const [patientName, setPatientName] = React.useState<string>('');
  const [relatives, setRelatives] = React.useState<
    {
      name: string;
      relationshipToPatient: string;
      personalNumber?: string;
    }[]
  >([]);

  const { isLoading } = useQuery(
    ['consentRequest', consentRequestId],
    () => service.getConsentRequest(consentRequestId || ''),
    {
      enabled: !!consentRequestId,
      onSuccess: (data) => {
        if (data) {
          const patientName = data.patientData.name;
          const patientPersonalNumber = data.patientData.personalNumber;
          setPatientName(patientName);
          const consents = data.relatives.filter(
            (rel) => rel.consentStatus === 'pending',
          );
          setRelatives(
            consents.map((consent) => {
              return {
                name: consent.name,
                relationshipToPatient: consent.relationshipToPatient || '',
                personalNumber: consent.personalNumber,
              };
            }),
          );
          if (consents.length === 0) {
            setState('no-pending-consents');
          }
          setNotification(
            getNewConsentNotification(
              t,
              translateRelationship,
              patientName,
              patientPersonalNumber,
              consents.map((consent) => {
                return {
                  name: consent.name,
                  relationshipToPatient: consent.relationshipToPatient || '',
                };
              }),
              true,
            ),
          );
        }
      },
      onError: (_error) => setState('not-found'),
    },
  );

  const sendMutation = useMutation(
    () => service.sendReminder(consentRequestId || ''),
    {
      onSuccess: (_data) => {
        setState('confirmation');
      },
    },
  );

  if (isLoading) return <LoadingBackdrop />;

  switch (state) {
    case 'notification-view':
      return (
        <MessageVerificationView
          title={t('consent.new-edit.consent-create')}
          message={notification}
          onBackButtonClick={() => {
            navigate(-1);
          }}
          onContinueClick={sendMutation.mutate}
          onContinueClickLoading={sendMutation.isLoading}
        />
      );
    case 'confirmation':
      return (
        <ConsentSentConfirmationView
          patientName={patientName}
          relatives={relatives}
        />
      );
    case 'no-pending-consents':
      return <NoPendingConsentsView />;
    case 'not-found':
      return <PageNotFoundView />;
  }
};

export default SendReminder;
