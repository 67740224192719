import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { RadioInput, TextInput } from 'components/inputs';
import RelationshipInput from 'features/common/controllers/RelationshipInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type SignerData = {
  proxySignerName?: string;
  proxySignerToConsenteeRelationship?: string | null;
  proxySignerPersonalNumber?: string;
};

export type SignerDataErrors = {
  [key in keyof SignerData]?: string | undefined;
};

interface SignerRelativeDeceasedProps {
  specificProxySigner?: boolean;
  signerData: SignerData;
  errors: SignerDataErrors;
  onDeleteClick?: () => void;
  handleSignerTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextInputChangeFactory: (
    key: keyof SignerData,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAutocompleteInputChangeFactory: (
    key: keyof SignerData,
  ) => (value: string | null) => void;
}

export const SignerRelativeDeceased = (props: SignerRelativeDeceasedProps) => {
  const { t } = useTranslation(['consent', 'common']);
  const theme = useTheme();

  const {
    specificProxySigner,
    signerData,
    errors,
    handleSignerTypeChange,
    handleAutocompleteInputChangeFactory,
    handleTextInputChangeFactory,
    onDeleteClick,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RadioInput
          label={t('relative-of-deceased.label')}
          id="relativeInfo"
          options={[
            {
              value: 'first-degree',
              label: t('relative-of-deceased.option.first-degree'),
            },
            {
              value: 'specific-relative',
              label: t('relative-of-deceased.option.specific-relative'),
            },
          ]}
          value={specificProxySigner ? 'specific-relative' : 'first-degree'}
          onChange={handleSignerTypeChange}
          rowDirection
          radioGroupProps={{
            row: true,
          }}
        />
      </Grid>
      {specificProxySigner && (
        <>
          <Grid item md={4}>
            <TextInput
              label={t('common:full-name')}
              id="proxySignerName"
              value={signerData.proxySignerName || ''}
              required
              error={
                errors?.proxySignerName &&
                t(`input-validation.${errors?.proxySignerName}`)
              }
              onChange={handleTextInputChangeFactory('proxySignerName')}
            />
          </Grid>
          <Grid item md={4}>
            <RelationshipInput
              label={t('relative-of-deceased.relation.label')}
              value={signerData.proxySignerToConsenteeRelationship || ''}
              onChange={handleAutocompleteInputChangeFactory(
                'proxySignerToConsenteeRelationship',
              )}
              optionsType="firstDregreeRelationship"
              required
              error={
                errors?.proxySignerToConsenteeRelationship &&
                t(
                  `input-validation.${errors?.proxySignerToConsenteeRelationship}`,
                )
              }
              placeholder={t('relative-of-deceased.relationship.placeholder')}
            />
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" gap={1} alignItems="center">
              <TextInput
                label={t('common:personal-number')}
                id="proxySignerPersonalNumber"
                value={signerData.proxySignerPersonalNumber || ''}
                onChange={handleTextInputChangeFactory(
                  'proxySignerPersonalNumber',
                )}
                placeholder={t('common:personal-number.placeholder')}
                error={
                  errors?.proxySignerPersonalNumber &&
                  t(`input-validation.${errors?.proxySignerPersonalNumber}`)
                }
                textFieldProps={{
                  inputProps: {
                    maxLength: 12,
                    minLength: 12,
                  },
                }}
                formLabelProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
              {onDeleteClick && (
                <Tooltip title={t('common:tooltip.delete')} arrow>
                  <IconButton
                    size="small"
                    onClick={onDeleteClick}
                    sx={{
                      color: theme.palette.common.brand.darkBlue,
                      width: 24,
                      height: 24,
                      mt: 3,
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
};
