import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useNotificationContext } from './NotificationProvider';

export const SnackbarNotification = () => {
  const { notification, setNotification } = useNotificationContext();
  const { message, type } = notification || {};

  if (!message) {
    return null;
  }

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{ maxWidth: 600 }}
      onClose={() => setNotification({ message: '' })}
    >
      <Alert severity={type} onClose={() => setNotification({ message: '' })}>
        {message}
      </Alert>
    </Snackbar>
  );
};
