import { Box, Stack, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { NodeFormController } from '../common/controllers/NodeFormController';
import ParentSelectionController from '../common/controllers/ParentSelectionController';
import { TwinSelectionFormController } from '../common/controllers/TwinSelectionFormController';
import { usePedigreeLayout } from '../common/hooks/usePedigreeLayout';
import { usePedigreeContext } from '../common/pedigreeContext';
import Dialog from '../common/views/Dialog';
import { PartnerConnections } from '../common/views/PartnerConnections';
import { PedigreeActionsButtons } from '../common/views/PedigreeActionsButtons';
import PedigreeEdges from '../common/views/PedigreeEdges';
import { ResponsiveRenderer } from '../common/views/ResponsiveRenderer';
import { SnackbarMessage } from '../common/views/SnackbarMessage';
import { NodeInformationController } from './controllers/NodeInformationController';
import NodeProfessionalController from './controllers/NodeProfessionalController';
import { usePedigreeFilter } from './hooks/usePedigreeFilter';
import { generateDiseaseToColorMap } from './utils/generateDiseaseToColorMap';
import { DiseaseLegend } from './views/DiseaseLegend';
import { GeneticTextResult } from './views/GeneticTextResult';
import { NodeDisplayFilter } from './views/NodeDisplayFilter';

const PedigreeProfessional = () => {
  const {
    pedigree,
    pedigreeType,
    drawerState,
    drawerDefaultWidth,
    selectedNodeId,
    highlightedNodeIds,
    stageRef,
    NodeMenu,
    service,
  } = usePedigreeContext();
  const theme = useTheme();

  const diagnoseToColorMap = useMemo(() => {
    if (!pedigree) return {};
    return generateDiseaseToColorMap(pedigree.nodes);
  }, [pedigree]);

  const { visibleNodeProperties, filterViewProps } =
    usePedigreeFilter(diagnoseToColorMap);

  const { layout, layoutSize } = usePedigreeLayout({
    pedigreeNodes: pedigree?.nodes || [],
    pedigreeType,
    visibleNodeInfoProperties: visibleNodeProperties,
  });

  const highlightedMode = !!highlightedNodeIds?.length;
  const showGeneticTest = pedigree.nodes.some(
    (node) => node.geneticTest?.result,
  );
  const showDiseaseLegend = Object.keys(diagnoseToColorMap).length > 0;

  const handleDownload = useCallback(
    () =>
      service.onPedigreeDownloadClick?.({
        showNames: visibleNodeProperties.name,
        showDiagnoses: visibleNodeProperties.diagnosis,
        showGenes: visibleNodeProperties.variants,
        showAges: visibleNodeProperties.age,
      }),
    [service, visibleNodeProperties],
  );

  const handlePrint = useCallback(
    () =>
      service.onPedigreePrintClick?.({
        showNames: visibleNodeProperties.name,
        showDiagnoses: visibleNodeProperties.diagnosis,
        showGenes: visibleNodeProperties.variants,
        showAges: visibleNodeProperties.age,
      }),
    [service, visibleNodeProperties],
  );

  return (
    <Box
      display="flex"
      id="pedigree-professional-container"
      flexDirection="column"
      sx={{ flexGrow: 1 }}
    >
      <Stack spacing={1.5} width={230} p={2} position="absolute" zIndex={10}>
        <NodeDisplayFilter
          {...filterViewProps}
          receivedAt={pedigree.receivedAt}
        />
        {showDiseaseLegend && (
          <DiseaseLegend diagnoseToColorMap={diagnoseToColorMap} />
        )}
        {showGeneticTest && <GeneticTextResult />}
      </Stack>
      <PedigreeActionsButtons
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        direction="row"
        right={selectedNodeId ? drawerDefaultWidth + 16 : 16}
      />
      <Dialog />
      <SnackbarMessage />
      {drawerState?.type === 'parentSelection' && <ParentSelectionController />}
      {drawerState?.type === 'nodeForm' && <NodeFormController />}
      {drawerState?.type === 'nodeInformation' && <NodeInformationController />}
      {drawerState?.type === 'twinSelection' && <TwinSelectionFormController />}
      <ResponsiveRenderer
        stageRef={stageRef}
        layoutSize={layoutSize}
        highlightedMode={highlightedMode}
      >
        <PedigreeEdges
          links={layout?.links}
          strokeOptions={{
            color: theme.palette.common.brand.darkBlue70,
            width: 1,
          }}
        />
        {pedigree.nodes.map((pedigreeNode) => (
          <NodeProfessionalController
            key={pedigreeNode.id}
            highlightedMode={highlightedMode}
            highlightedNode={!!highlightedNodeIds?.includes(pedigreeNode.id)}
            position={layout?.nodes[pedigreeNode.id]?.pos}
            pedigreeNode={pedigreeNode}
            isSelected={selectedNodeId === pedigreeNode.id}
            diagnoseToColorMap={diagnoseToColorMap}
            visibleNodeProperties={visibleNodeProperties}
          />
        ))}
        <PartnerConnections
          pedigreeLayoutNodes={layout?.nodes}
          color={theme.palette.common.brand.darkBlue}
        />
      </ResponsiveRenderer>
      {NodeMenu}
    </Box>
  );
};

export default PedigreeProfessional;

const pseudoPartnerNodeIdRegExPattern = /^p\.\S+\.\S+$/;
export const isPseudoPartnerId = (id: string) =>
  pseudoPartnerNodeIdRegExPattern.test(id);
