import { createContext, PropsWithChildren, useContext, useState } from 'react';

export type Message = {
  message: string;
  type?: 'success' | 'error' | 'warning' | 'info';
};

export type ContextProps = {
  notification: Message | null;
  setNotification: (notification: Message | null) => void;
};

export const Context = createContext<ContextProps>({
  notification: null,
  setNotification: () => {},
});

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notification, setNotification] = useState<Message | null>(null);

  const contextValue = {
    notification,
    setNotification,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useNotificationContext = () => useContext(Context);
