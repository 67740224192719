import { Container, Stack } from '@mui/material';
import {
  AddConsentsToExistingConsent,
  ConsentRequestSuggestion,
  CreateConsentRequestForPatient,
} from 'api/consentManagement/consent-management-service';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { PatientData } from 'iPedigree/features/common/PatientFormController';
import { ConsentRequestReview } from 'iPedigree/features/patientPage/ConsentRequestReview';
import ConsentSentConfirmation from 'iPedigree/features/patientPage/ConsentSentConfirmation';
import {
  NewConsentController,
  RelativeFormData,
} from 'iPedigree/features/patientPage/newConsent/controllers/NewConsentController';
import { defaultRelativeData } from 'iPedigree/features/patientPage/newConsent/controllers/RelativesFormController';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export type NewConsentFormData = {
  patientData: PatientData;
  relatives: RelativeFormData[];
  consentFor: string;
};

export const RequestNewConsentPage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const service = useStaffPedigreeCaseService();
  const consentService = useConsentManagementService();
  const location = useLocation();
  const navigate = useNavigate();

  const [pageState, setPageState] = useState<
    'form' | 'review' | 'confirmation'
  >('form');

  const [newConsentFormData, setNewConsentFormData] =
    useState<NewConsentFormData | null>(null);

  const isAddNewConsentsToExistingRequest =
    location.state?.isAddNewConsentsToExistingRequest || false;

  const enabledGetConsentrequest =
    !isAddNewConsentsToExistingRequest && !!patientId && pageState === 'form';

  const { isLoading: isConsentRequestLoading } = useQuery(
    ['consentRequest', patientId],
    () => consentService.getConsentRequestByPatientId(patientId || ''),
    {
      enabled: enabledGetConsentrequest,
      onSuccess: (data) => {
        if (data) {
          const newPathname = location.pathname.replace(
            '/consents/new-consent',
            '',
          );
          return navigate(newPathname);
        }
      },
    },
  );

  const { data: pedigreeCase, isLoading: isPedigreeCaseLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service.getPedigreeCase(patientId || ''),
    {
      enabled: newConsentFormData === null,
      onSuccess: ({ patientDetails }) => {
        const relatives = location.state?.selectedRelatives || [];
        setNewConsentFormData({
          patientData: {
            name: patientDetails?.name || '',
            personalNumber: patientDetails?.personalNumber || '',
            email: patientDetails?.email || '',
            phoneNumber: patientDetails?.phoneNumber || '',
          },
          relatives: relatives.length
            ? formatedSelectedRelatives(relatives)
            : relatives,
          consentFor: '',
        });
      },
    },
  );

  const handleContinueClick = (data: NewConsentFormData) => {
    setNewConsentFormData(data);
    setPageState('review');
  };

  const createNewConsentMutation = useMutation(
    (data: CreateConsentRequestForPatient) =>
      consentService.createConsentRequest(patientId || '', data),
    {
      onSuccess: () => setPageState('confirmation'),
    },
  );

  const addNewConsentMutation = useMutation(
    (data: AddConsentsToExistingConsent) =>
      consentService.addConsentsToExistingConsent(patientId || '', data),
    {
      onSuccess: () => {
        navigate(location.pathname, {
          replace: true,
          state: { isAddNewConsentsToExistingRequest: false },
        });
        setPageState('confirmation');
      },
    },
  );

  const formatPayload = useCallback(
    (data: NewConsentFormData): CreateConsentRequestForPatient => {
      const { patientData, relatives, consentFor } = data || {};
      return {
        patientContactDetails: {
          phoneNumber: patientData.phoneNumber || undefined,
          email: patientData.email,
        },
        relatives: relatives.map(
          ({ id, disabled, specificProxySigner, ...relatives }) => relatives,
        ),
        consentFor,
      };
    },
    [],
  );

  const handleOnSubmitClick = () => {
    if (!newConsentFormData) {
      return;
    }
    if (isAddNewConsentsToExistingRequest) {
      const payload = formatPayload(newConsentFormData);
      return addNewConsentMutation.mutate({
        patientContactDetails: payload.patientContactDetails,
        relatives: payload.relatives,
      });
    }
    return createNewConsentMutation.mutate(formatPayload(newConsentFormData));
  };

  const formatedSelectedRelatives = useCallback(
    (relatives: ConsentRequestSuggestion[]) => {
      return relatives.map(
        (relative: ConsentRequestSuggestion, index: number) => {
          return {
            ...defaultRelativeData,
            id: index,
            name: relative.relativeName,
            personId: relative.personId,
            relationshipToPatient: relative.relation,
            deceased: relative.deceased,
            disabled: true,
          };
        },
      );
    },
    [],
  );

  if (isPedigreeCaseLoading || isConsentRequestLoading)
    return <LoadingBackdrop />;

  if (!pedigreeCase) return null;

  if (pageState === 'confirmation') {
    const { patientData, relatives } = newConsentFormData || {};

    return (
      <Container>
        <Stack pt={3} pb={3}>
          <ConsentSentConfirmation
            patientName={patientData?.name || ''}
            relatives={relatives || []}
          />
        </Stack>
      </Container>
    );
  }

  if (pageState === 'review') {
    const { patientData, relatives } = newConsentFormData || {};

    return (
      <Container>
        <Stack pt={3} pb={3}>
          <ConsentRequestReview
            patientName={patientData?.name || ''}
            patientPersonalNumber={patientData?.personalNumber || ''}
            relatives={relatives || []}
            onContinueClick={handleOnSubmitClick}
            onBackButtonClick={() => setPageState('form')}
          />
        </Stack>
      </Container>
    );
  }

  return (
    <Container>
      <Stack pt={3} pb={3}>
        <NewConsentController
          initialFormValues={newConsentFormData}
          onContinueClick={handleContinueClick}
        />
      </Stack>
    </Container>
  );
};
