import { TFunction } from 'i18next';

function getRelativeList(
  relatives: Consent[],
  translateRelationship: (relationship: string) => string,
): string {
  return relatives
    .map((relative) => {
      return `\u2022 ${relative.name} (${translateRelationship(
        relative.relationshipToPatient || '',
      )})`;
    })
    .join('\n');
}

function getNewConsentNotification(
  t: TFunction,
  translateRelationship: (relationship: string) => string,
  patientName: string,
  patientPersonalNumber: string,
  relatives: Consent[],
  reminder?: boolean,
): string {
  const relativesList = getRelativeList(relatives, translateRelationship);
  return (
    `${
      reminder
        ? `${t('consent-management.notification.reminder-title')}:\n`
        : `${t('consent-management.notification.new-request-title')}:\n`
    }` +
    `${t('consent-management.notification.patient')}: ${patientName}\n` +
    `${t(
      'consent-management.notification.personal-number',
    )}: ${patientPersonalNumber}\n\n` +
    `${t('consent-management.notification.for-relatives')}:\n` +
    `${relativesList}`
  );
}

type Consent = {
  name: string;
  relationshipToPatient: string | null;
};

export { getNewConsentNotification };
