import { Diagnose, Sex } from 'pedigree/features/common/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PedigreeNodeConsumerView from '../views/PedigreeNodeConsumerView';
import {
  NodeMemberMenuValue,
  PedigreeNodeMemberMenuController,
} from './PedigreeNodeMenuController';

interface PedigreeNodeControllerProps {
  isIndex: boolean;
  sex: Sex;
  hasDiagnoseHistory: boolean;
  relativeRelationLabel: string;
  allowNodeClick?: boolean;
  isSelected: boolean;
  nodeSize: { width: number; height: number };
  onNodeClick: (isSelected: boolean) => void;
  handleMemberMenuOptionClick?: (value: NodeMemberMenuValue) => void;
  diagnoses: Diagnose[];
  deceased: boolean;
  name?: string;
}

const PedigreeNodeController: React.FC<PedigreeNodeControllerProps> = (
  props,
) => {
  const { t } = useTranslation('myFamilyTree');

  const nodeInfoLabel = useMemo(() => {
    const { hasDiagnoseHistory, diagnoses, deceased } = props;
    let cancerHistoryLabel = '';
    let addDiagnosisLabel = '';
    let deceasedLabel = '';

    if (!!hasDiagnoseHistory) {
      cancerHistoryLabel = t('pedigree.node.member.cancerHistory.label');
    }

    const validDiagnoses = diagnoses.filter(
      ({ diseaseName }) =>
        !!diseaseName && diseaseName !== 'null' && diseaseName !== 'undefined',
    );

    if (!!hasDiagnoseHistory && !validDiagnoses.length) {
      addDiagnosisLabel = t('pedigree.node.member.addDiagnosis.label');
    }

    if (!!deceased) {
      deceasedLabel = t('pedigree.node.member.isDeceased.label');
    }

    return {
      cancerHistoryLabel,
      addDiagnosisLabel,
      deceasedLabel,
    };
  }, [props, t]);

  const handleNodeClick = () => {
    if (props.allowNodeClick && !props.isIndex) {
      props.onNodeClick(!props.isSelected);
    }
  };

  return (
    <>
      <PedigreeNodeConsumerView
        {...{
          sex: props.sex,
          name: props.name,
          isIndex: props.isIndex,
          isSelected: props.isSelected,
          relativeRelationLabel: props.relativeRelationLabel,
          nodeSize: props.nodeSize,
          onClick: handleNodeClick,
          hasCancerHistory: props.hasDiagnoseHistory,
          hasError: !!nodeInfoLabel.addDiagnosisLabel && !props.isSelected,
          cancerHistoryLabel: nodeInfoLabel.cancerHistoryLabel,
          addDiagnosisLabel: nodeInfoLabel.addDiagnosisLabel,
          deceasedLabel: nodeInfoLabel.deceasedLabel,
        }}
      />
      {props.handleMemberMenuOptionClick && (
        <PedigreeNodeMemberMenuController
          handleMemberMenuOptionClick={props.handleMemberMenuOptionClick}
        />
      )}
    </>
  );
};

export default PedigreeNodeController;
