import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { formatPersonalNumber } from 'common/utils';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import AddRelativeForm from 'features/consentManagement/addRelative/controllers/AddRelativesForm';
import ConsentRequestNotFound from 'features/consentManagement/addRelative/views/ConsentRequestNotFound';
import { defaultRelativeData } from 'features/consentManagement/common/consts';
import {
  AddRelativesPayload,
  RelativeFormData,
} from 'features/consentManagement/common/types';
import { getNewConsentNotification } from 'features/consentManagement/common/utils/notification-template';
import ConsentSentConfirmationView from 'features/consentManagement/common/views/ConsentSentConfirmationView';
import MessageVerificationView from 'features/consentManagement/common/views/MessageVerificationView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export type AddRelativesPageState =
  | 'add-relatives-form'
  | 'add-relatives-message-verification'
  | 'add-relatives-confirmation';

function AddRelatives() {
  const service = useConsentManagementService();
  const { consentRequestId } = useParams();
  const { t } = useTranslation('all');
  const { translateRelationship } = useRelationshipOptions();

  const [relatives, setRelatives] = React.useState<RelativeFormData[]>([
    defaultRelativeData,
  ]);

  const [addRelativeNotification, setAddRelativeNotification] =
    React.useState<string>('');

  const [currentState, setCurrentState] =
    React.useState<AddRelativesPageState>('add-relatives-form');

  const {
    data: consentRequest,
    isLoading,
    error: getConsentRequestError,
  } = useQuery(
    ['consentRequest', consentRequestId],
    () => service.getConsentRequest(consentRequestId || ''),
    {
      enabled: !!consentRequestId,
    },
  );

  let notFound = false;
  if (getConsentRequestError) {
    const getConsentRequestErrorResponse = (getConsentRequestError as any)
      .response;
    notFound = has404Or400Error(getConsentRequestErrorResponse);
  }

  const patientName = consentRequest?.patientData.name || '';
  const patientPersonalNumber = formatPersonalNumber(
    consentRequest?.patientData.personalNumber || '',
  );

  const addRelativesMutation = useMutation(
    service.addRelativesToConsentRequest,
    {
      onSuccess: () => {
        setCurrentState('add-relatives-confirmation');
      },
    },
  );

  const handleOnFormContinueClick = () => {
    setAddRelativeNotification(
      getNewConsentNotification(
        t,
        translateRelationship,
        patientName,
        patientPersonalNumber,
        relatives,
      ),
    );

    setCurrentState('add-relatives-message-verification');
  };

  const toAddRelativesPayload = (
    relativesFormData: RelativeFormData[],
  ): AddRelativesPayload => {
    return relativesFormData.map((relative) => {
      const { specificProxySigner, ...rest } = relative;
      return rest;
    }) as AddRelativesPayload;
  };

  const handleOnSendClick = () => {
    addRelativesMutation.mutate({
      consentRequestId: consentRequestId || '',
      relatives: toAddRelativesPayload(relatives),
    });
  };

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (notFound) {
    return <ConsentRequestNotFound consentRequestId={consentRequestId || ''} />;
  }

  switch (currentState) {
    case 'add-relatives-message-verification':
      return (
        <MessageVerificationView
          title={t('consent.new-edit.consent-create')}
          message={addRelativeNotification}
          onBackButtonClick={() => {
            setCurrentState('add-relatives-form');
          }}
          onContinueClick={handleOnSendClick}
          onContinueClickLoading={addRelativesMutation.isLoading}
        />
      );
    case 'add-relatives-confirmation':
      return (
        <ConsentSentConfirmationView
          patientName={patientName}
          relatives={relatives}
        />
      );
    default:
      return (
        <AddRelativeForm
          relatives={relatives}
          setRelatives={setRelatives}
          patientName={patientName}
          patientPersonalNumber={patientPersonalNumber}
          onContinueClick={handleOnFormContinueClick}
        />
      );
  }
}

const has404Or400Error = (error: any) =>
  error?.status === 404 || error?.status === 400;

export default AddRelatives;
