import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography } from '@mui/material';
import { ConsentRequest } from 'api/consentManagement/consent-management-service';
import useConsentActions from 'features/consentManagement/listPatients/hooks/useConsentActions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ConsentsTable from './ConsentsTable';

interface PatientConsentsProps {
  consentRequest: ConsentRequest;
  onAddRelativesButtonClick?: () => void;
  onSendReminderButtonClick: () => void;
}

const PatientConsents: React.FC<PatientConsentsProps> = ({
  consentRequest,
  onAddRelativesButtonClick,
  onSendReminderButtonClick,
}) => {
  const { t } = useTranslation('consent');
  const { getConsentActions } = useConsentActions();

  const consents = useMemo(
    () =>
      consentRequest.relatives.map((consent) => {
        const deceasedSigner =
          consent.deceased &&
          consent.proxySignerName &&
          consent.proxySignerToConsenteeRelationship
            ? {
                name: consent.proxySignerName,
                relation: consent.proxySignerToConsenteeRelationship,
                personalNumber: consent?.proxySignerPersonalNumber,
              }
            : undefined;
        return {
          ...consent,
          relative: consent.name,
          personalNumber: consent?.personalNumber,
          relation: consent.relationshipToPatient ?? undefined,
          date: consent.updatedAt,
          deceasedSigner,
          actions: getConsentActions(consent),
        };
      }),
    [consentRequest, getConsentActions],
  );

  const hasPendingConsents = useMemo(() => {
    return consentRequest.relatives.some(
      (relative) => relative.consentStatus === 'pending',
    );
  }, [consentRequest]);

  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">{t('patient-consents.title')}</Typography>
        <Button
          variant="text"
          color="primary"
          onClick={onSendReminderButtonClick}
          sx={{
            width: 'fit-content',
          }}
          disabled={!hasPendingConsents}
          startIcon={<FontAwesomeIcon icon={faBell} />}
        >
          {t('patient-consents.send-reminder')}
        </Button>
      </Stack>
      <ConsentsTable
        consents={consents}
        consentFor={consentRequest.consentFor}
      />
      {onAddRelativesButtonClick && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onAddRelativesButtonClick}
          sx={{
            width: 'fit-content',
          }}
        >
          {t('patient-consents.add-relatives.button-text')}
        </Button>
      )}
    </Stack>
  );
};

export default PatientConsents;
